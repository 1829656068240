import { useState, useCallback } from 'react';

/**
 * @typedef {{
 *    isOpen: boolean;
 *    onOpen: () => void;
 *    onClose: () => void;
 *    onToggle: () => void;
 * }} returnObject
 */

/**
 * @name useDisclosure
 * @description Custom hook for managing open/close or toggle states in a component
 * @param {true | false} initialState - The initial state of the component either true or false
 * @returns {returnObject}
 * @example
 * const { isOpen, onOpen, onClose, onToggle } = useDisclosure();
 */

function useDisclosure(initialState = false) {
	const [isOpen, setIsOpen] = useState(initialState);

	const onOpen = useCallback(() => setIsOpen(true), []);
	const onClose = useCallback(() => setIsOpen(false), []);
	const onToggle = useCallback(() => setIsOpen((prevState) => !prevState), []);

	return { isOpen, onOpen, onClose, onToggle };
}
export default useDisclosure;
